
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

// Components
import AutoComplete from "primevue/autocomplete";

// Services
import GlChartService from "@/services/GlChartsService";
const glChartsService = new GlChartService();

// Types
interface Data {
  query: string;
  suggestions: string[];
  accounts: any[];
}

export default defineComponent({
  name: "AccountAutocomplete",
  components: {
    AutoComplete,
  },
  emits: ["item-select", "on-leave"],
  props: {
    selectedId: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data(): Data {
    return {
      query: "",
      suggestions: [],
      accounts: [] as any[],
    };
  },
  watch: {
    selectedId() {
      this.getContactById();
    },
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
    }),
  },
  methods: {
    handleComplete() {
      this.suggestions = this.accounts.filter((account: any) => {
        return (
          account.acct.toLowerCase().includes(this.query.toLowerCase()) ||
          account.desc.toLowerCase().includes(this.query.toLowerCase())
        );
      });
    },
    getAccounts() {
      glChartsService.getAccounts("", this.getClient).then((response: any) => {
        this.accounts = response.glchart_items;
      });
    },
    handleItemSelect(event: any) {
      this.$emit("item-select", event.value);
    },
    getFullOption(account: any) {
      let acct = account.acct;
      if (account.desc) {
        acct += " - " + account.desc;
      }
      return acct;
    },
    getContactById() {
      if (this.selectedId) {
        this.query = this.selectedId;
      }
    },
    handleOnBlur(event: any) {
      this.$emit("on-leave", event);
    },
  },
  created() {
    this.getAccounts();
    this.getContactById();
  },
  mounted() {
    // Preset Selected Customer From Filter
    this.getContactById();
  },
});
