import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, {
    modelValue: _ctx.query,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
    placeholder: "Enter ID or Description...",
    suggestions: _ctx.suggestions,
    field: _ctx.getFullOption,
    disabled: _ctx.disabled,
    onComplete: _ctx.handleComplete,
    onItemSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleItemSelect($event))),
    onBlur: _ctx.handleOnBlur
  }, null, 8, ["modelValue", "suggestions", "field", "disabled", "onComplete", "onBlur"]))
}